<template>
  <div class="mt-2">
    <teleport
      selector="#button"
    >
      <b-button
        class="px-6 general-add-new-btn btn-primary-light"
        @click="save()"
      >
        <b-icon
          icon="content-save"
          size="is-small"
        />Save
      </b-button>
      <b-button
        class="px-6 general-add-new-btn btn-primary-light"
        @click="run()"
      >
        <b-icon
          icon="play"
          size="is-small"
        />Run
      </b-button>
    </teleport>
    <div class="columns is-multiline">
      <div
        v-for="(node, index) in test.data.nodes"
        :key="index"
        class="column is-6"
      >
        <b-collapse
          aria-id="contentIdForA11y2"
          class="panel is-shadowless"
          style="border: 1px solid #dedede"
          animation="slide"
        >
          <template #trigger>
            <div
              class="panel-heading"
              role="button"
              aria-controls="contentIdForA11y2"
            >
              <strong>{{ node.name }}</strong>
            </div>
          </template>

          <div
            v-for="(variable, varIndx) in node.variables"
            :key="varIndx"
            class="p-2"
          >
            <small>{{ variable.name }}</small>
            <b-input
              v-model="variable.value"
              size="is-small"
            />
          </div>

          <div
            v-if="node.type === 'Send Email'"
            class="p-2"
          >
            <div class="mb-2">
              <small>From</small>
              <editor
                v-model="node.info.from"
                :input="true"
              />
            </div>

            <div class="mb-2">
              <small>To</small>
              <editor
                v-model="node.info.to"
                :input="true"
              />
            </div>

            <div class="mb-2">
              <small>Subject</small>
              <editor
                v-model="node.info.subject"
                :input="true"
              />
            </div>

            <div class="mb-2">
              <small>Body</small>
              <editor
                v-model="node.info.body"
              />
            </div>
          </div>
        </b-collapse>
      </div>
    </div>
    <b-notification
      v-if="hasPassed === 'yes'"
      type="is-success"
      aria-close-label="Close notification"
    >
      Test has successfully passed
    </b-notification>
    <b-notification
      v-if="hasPassed === 'no'"
      type="is-danger"
      aria-close-label="Close notification"
    >
      Test failed
    </b-notification>
  </div>
</template>

<script >
import { defineAsyncComponent, onMounted, ref } from '@vue/composition-api';
import { fetchAutoTestByIdService, fetchAutoTestService, runAutoTestByIdService, updateAutoTestService } from '@/services/autotest-service/moduleTestRequests';
import { useRoute } from '@/hooks/vueRouter';
import Editor from '@/modules/core/components/wysiwyg/Editor';
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const test = ref({});
  const route = useRoute();
  let hasPassed = ref(null);

  const fetchTest = async () => {
    try {
      const {
        data: {
          data
        }
      } = await fetchAutoTestByIdService(route.params.testId);
      test.value = data;
      test.value.data = JSON.parse(data.data);
      console.log(test.value.data.nodes);
    } catch (err) {
      console.error(err);
    }
  };

  const save = async () => {
    try {
      await updateAutoTestService(route.params.testId, test.value);
    } catch (err) {
      console.log(err);
    }
  };

  const run = async () => {
    hasPassed.value = null;

    try {
      const {
        data: {
          data
        }
      } = await runAutoTestByIdService(route.params.testId);

      if (data.has_passed) {
        hasPassed.value = 'yes';
      } else {
        hasPassed.value = 'no';
      }
    } catch (err) {
      console.log(err);
    }
  };

  onMounted(() => {
    fetchTest();
  });
  return {
    test,
    hasPassed,
    save,
    run
  };
};

__sfc_main.components = Object.assign({
  Editor
}, __sfc_main.components);
export default __sfc_main;
</script>


<style lang="scss">
@import '~@/style/variables.scss';
@import '~@/style/components.scss';
</style>